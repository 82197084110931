import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import Seo from "../components/layout/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <h1>Sorry, we couldn’t find the page you’re looking for.</h1>
      <p>The link may be outdated or the page may have moved.</p>
      <p>Go to <a href="https://blog.essentialwholesale.com">Blog Home</a></p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
